import controller_0 from '@symfony/ux-autocomplete/dist/controller.js';
import 'tom-select/dist/css/tom-select.bootstrap5.css';
import controller_1 from '@symfony/ux-dropzone/dist/controller.js';
import '@symfony/ux-dropzone/src/style.css';
import controller_2 from '@symfony/ux-live-component/dist/live_controller.js';
import '@symfony/ux-live-component/styles/live.css';
import controller_3 from '@symfony/ux-turbo/dist/turbo_controller.js';
import controller_4 from '@symfony/ux-typed/dist/controller.js';
export default {
  'symfony--ux-autocomplete--autocomplete': controller_0,
  'symfony--ux-dropzone--dropzone': controller_1,
  'live': controller_2,
  'symfony--ux-turbo--turbo-core': controller_3,
  'symfony--ux-typed': controller_4,
};